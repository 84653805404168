// components/Home.js
import Maps from '../../components/maps/Maps';
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, styled, TextField, Typography } from '@mui/material';
import "./contactUs.css";
import contactImageBackground from "../../assets/images/contactImageBackground.png";
import { Link } from 'react-router-dom';
import contactFinalImage from "../../assets/images/graphic/4.png";
import contactPageBackground from "../../assets/images/Images/contactPageBackground.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import contactLastImage from "../../assets/images/graphic/5.png";
import { CustomTextField } from '../../App';

export function ContactUs() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
    backgroundImage: `url(${contactFinalImage})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
      minHeight: '25vh'
    }
  });

  const BackgroundContainerS = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${contactFinalImage})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',
    marginBottom: "4vh",
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container>
            <Grid container sx={{ alignItems: "center", justifyContent: "space-between"}}>
              <Grid item xs={12} md={4}>
                <BackgroundContainerS sx={{  backgroundImage: `url(${contactPageBackground})`, display: {xs: 'none', md: 'flex'} }}>
                </BackgroundContainerS>
              </Grid>

              <Grid item xs={12} md={7.5}>
                <BackgroundContainer>
                  <Typography gutterBottom className='Heading' variant="h1" sx={{ color: "#1F140B", fontSize: {xs: '1.5rem !important', md: '4rem !important'}, margin: '2vh auto'}}>
                      Let's Get In Touch
                  </Typography>

                  <Grid item xs={8} md={12}>
                    <Typography gutterBottom variant="body1" className="mainText">
                      We love hearing from our customers!
                    </Typography>
                    
                    <Typography gutterBottom variant="body1" className="mainText">
                      Feedback, testimonies, questions and concerns- all are welcome!
                    </Typography>
                  </Grid>
                
                </BackgroundContainer>
              </Grid>
            </Grid>

            <Grid item xs={8} margin="8vh auto" flexDirection="column">
              <Typography variant='h4' className="subHeading" sx={{ fontSize: {xs: '2rem !important', md: '4rem !important'} }}>Send us a message</Typography>

              <Typography variant='body1' className="mainText">
                We will promptly get in touch to assist with any questions and concerns
              </Typography>

              <Grid container mt={4} spacing={2}>
                <Grid item xs={12} sm={6} py={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                  <CustomTextField id="outlined-basic" label="Name" variant="outlined" />
                  <CustomTextField id="outlined-basic" label="Email" variant="outlined" />
                  <CustomTextField id="outlined-basic" label="Subject" variant="outlined" />
                </Grid>

                <Grid item xs={12} sm={6} py={4}>
                  <CustomTextField fullWidth id="fullWidth" label="Message" multiline rows={8} />
                </Grid>

                <Grid container sx={{ display: "flex", justifyContent: {xs: "center", md: "flex-end"} }}>
                  <Button variant="contained" className="secondButton" sx={{ width: {xs: "30vw !important", md: "10vw !important"}}}>Contact</Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid container flexDirection="column">
              <Typography variant="h2" className="subHeading" gutterBottom sx={{ fontSize: {xs: '2rem !important', md: '4rem !important'} }}>Follow us on our Socials</Typography>

              <Grid container margin="auto">
                <Grid item xs={8} md={12} margin="auto">
                  <Typography variant="body1" className="mainText" margin="4vh auto" gutterBottom>
                    Keep up to date  with any news, <br/>weekly menu releases and learn some yummy recipes too
                  </Typography>
                </Grid>
              </Grid>

              

              <Grid container flexDirection="column" margin="auto">
                <Link to="#" className="footerLinks">
                  <Typography variant='body1' className="mainText footerLinks"> 
                  <FontAwesomeIcon icon={faInstagram} size="2x" style={{marginRight: "2vw"}} /> comfortcrumbs_ 
                  </Typography>
                </Link>

                <br/>

                <Link to="#" className="footerLinks">
                  <Typography variant='body1' className="mainText footerLinks"> 
                    <FontAwesomeIcon icon={faTiktok} size="2x" style={{marginRight: "2vw"}} /> comfortcrumbs_ 
                  </Typography>
                </Link>

                <br/>

                <Link to="#" className="footerLinks">
                  <Typography variant='body1' className="mainText footerLinks"> 
                    <FontAwesomeIcon icon={faFacebook} size="2x" style={{marginRight: "2vw"}} /> Comfort Crumbs
                  </Typography>
                </Link>
              </Grid>
             
            </Grid>


                
            <Grid container flexDirection="column" margin="10vh auto" justifyContent="center" alignItems="center">
              <Typography gutterBottom variant="h2" className="subHeading" sx={{ fontSize: {xs: '2rem !important', md: '4rem !important'} }}>
                Contact us Directly
              </Typography>


              <Grid container margin="auto">
                <Grid item xs={6} md={12} margin="auto">
                  <Typography variant="body1" className="mainText" gutterBottom> 
                    TEL: +27 73 211 8538 for any queries and admin
                  </Typography>

                  <Typography variant="body1" className="mainText" gutterBottom> 
                    TEL: +27 73 211 8538 for all orders
                  </Typography>

                  <Typography variant="body1" className="mainText" gutterBottom> 
                    Cape Town 
                  </Typography>

                  <Typography variant="body1" className="mainText" gutterBottom> 
                    OPENING HOURS: 8:00- 21:00 
                  </Typography>
                </Grid>
              </Grid>
             
            </Grid>

            <Grid container>
              <BackgroundContainerS sx={{  backgroundImage: `url(${contactLastImage})` }}>

                <Grid container>
                  <Grid item xs={10} md={12} margin="auto">
                    <Typography variant="body1" className="mainText" sx={{ color: "#FFE7A8"}}>
                      Thank you for visiting our website
                      and a special thank you to all our customers
                    </Typography>

                    <Typography variant="body1" className="mainText" sx={{ color: "#FFE7A8"}}>
                      Come again soon!
                    </Typography>
                  </Grid>
                </Grid>
              </BackgroundContainerS>
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
