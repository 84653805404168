import { AccountInfo } from "./pages/accountInfo/accountInfo";
import { CheckOut } from "./pages/checkOut/checkOut";
import { ContactUs } from "./pages/contact/contactUs";
import { FAQ } from "./pages/faq/faq";
import { Home } from "./pages/home/home"
import { OnlineOrders } from "./pages/onlineOrders/onlineOrders";
import { OrderDetails } from "./pages/orderDetails/orderDetails";
import { PaymentInfo } from "./pages/payment/paymentInfo";
import { ProductDetails } from "./pages/productDetails/productDetails"
import { Products } from "./pages/products/products";
import { ProductTypes } from "./pages/productTypes/productTypes"
import { ShippingRefunds } from "./pages/shipping & refunds/shippingRefunds";
import { StorePolicy } from "./pages/storePolicy/storePolicy";
import { Protected } from './pages/userHome/userHome';

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Info',
        Navigation: '/info',
        Protected: false,
        Component: FAQ,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Products',
        Navigation: '/products',
        Protected: false,
        Component: Products,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Menu',
        Navigation: '/menu',
        Protected: false,
        Component: ProductTypes,
        NavBarVisibile: true
    },
    {
        DisplayName: 'About',
        Navigation: '/meal-details/:productId',
        Protected: false,
        Component: ProductDetails,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Contact',
        Navigation: '/contact-us',
        Protected: false,
        Component: ContactUs,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Shipping & Refunds',
        Navigation: '/shipping',
        Protected: false,
        Component: ShippingRefunds,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Store Policy',
        Navigation: '/policy',
        Protected: false,
        Component: StorePolicy,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Online Orders',
        Navigation: '/onlineorders',
        Protected: false,
        Component: OnlineOrders,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Checkout',
        Navigation: '/checkout',
        Protected: false,
        Component: CheckOut,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Order Details',
        Navigation: '/order-details',
        Protected: false,
        Component: OrderDetails,
        NavBarVisibile: false
    },
    
]

export const SettingRoutes : AppRoute[] = [
    {
        DisplayName: 'Profile',
        Navigation: '/profile',
        Protected: true,
        Component: Protected,
        NavBarVisibile: true
    },
]