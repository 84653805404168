import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { products } from '../../data/products';
import  meals from '../../data/meals';
import { useCart } from '../../context/cartContext';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import './productTypes.css';
import { CardActionArea } from '@mui/material';
import iconImage1 from "../../assets/iconImage1.svg";
import iconImage2 from "../../assets/iconImage2.svg";
import iconImage3 from "../../assets/iconImage3.svg";
import productSectionImage1 from "../../assets/productSectionImage1.png";
import productSectionImage2 from "../../assets/productSectionImage2.png";
import productSectionImage3 from "../../assets/productSectionImage3.png";
import productSectionImage4 from "../../assets/productSectionImage4.png";
import  productsImage from "../../assets/productsImage.jpg"
import { HashLink as Link } from 'react-router-hash-link';
import QuantityInput from '../../components/looks/NumberInput';
import mainPageImage from "../../assets/mainMealImage.jpg";
import spiceBowl from "../../assets/images/spiceBowl.jpg.jpg";
import { styled } from '@mui/material/styles';
import mealImage from "../../assets/images/mealPhoto.jpg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import orderingImage from "../../assets/images/orderingImage.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//Images
import product1 from '../../assets/images/lemonadeImage.jpg';
import product2 from '../../assets/images/coffeeImage.jpg';
import product3 from '../../assets/images/greenSmoothie.jpg';
import product4 from '../../assets/images/mangoLassi.jpg';
import product5 from '../../assets/images/coconutWater.jpg';
import product6 from '../../assets/images/brownieImage.jpg';
import product7 from '../../assets/images/cheeseCake.jpg';
import product8 from '../../assets/images/applePie.jpg';
import product9 from '../../assets/images/mangoSorbet.jpg';
import product10 from '../../assets/images/chocolateMousse.jpg';
import product11 from '../../assets/images/tiramisu.jpg';
import product12 from '../../assets/images/fruitSalad.jpg';
import product13 from '../../assets/images/baklava.jpg';
import product14 from '../../assets/images/bruschetta.jpg';
import product15 from '../../assets/images/stuffedMushrooms.jpg';
import product16 from '../../assets/images/mangoLassi.jpg';
import product17 from '../../assets/images/garlicBread.jpg';
import product18 from '../../assets/images/nachosImages.jpg';
import product19 from '../../assets/images/falafelBites.jpg';
import product20 from '../../assets/images/hummusPlatter.jpg';
import product21 from '../../assets/images/veganBurger.jpg';
import product22 from '../../assets/images/chickenSandwich.jpg';
import product23 from '../../assets/images/margheritaPizza.jpg';
import product24 from '../../assets/images/dumplingStew.jpg';
import product25 from '../../assets/images/casesarSalad.jpg';
import product26 from '../../assets/images/chickenTacos.jpg';
import product27 from '../../assets/images/baconAndHamPizza.jpg';
import product28 from '../../assets/images/beefBurrito.jpg';
import product29 from '../../assets/images/carrotAndPotatoSoup.jpg';
import product30 from '../../assets/images/chickenNoodleSoup.jpg';
import MenuCard from '../../components/looks/MenuCard';
import ConttactSection from '../../components/looks/ContactSection';
import landingImage from "../../assets/images/graphic/4.png";
import chickenSandwich from "../../assets/images/chickenSandwich.jpg";

const  imageMap: { [key: string]: string } = {
 'images/lemonade.jpg': product1,
  'images/coffeeImage.jpg': product2,
  'greenSmoothie.jpg': product3,
  'images/mangoLassi.jpg': product4,
  'images/coconutWater.jpg': product5,
  'images/brownieImage.jpg': product6,
  'images/cheeseCake.jpg': product7,
  'images/applePie.jpg': product8,
  'images/mangoSorbet.jpg': product9,
  'images/chocolateMousse.jpg': product10,
  'images/tiramisu.jpg': product11,
  'images/fruitSalad.jpg': product12,
  'images/baklava.jpg': product13,
  'images/bruschetta.jpg': product14,
  'images/stuffedMushrooms.jpg': product15,
  'images/springRolls.jpg': product16,
  'images/garlicBread.jpg': product17,
  'images/nachosImages.jpg': product18,
  'images/falafelBites.jpg': product19,
  'images/hummusPlatter.jpg': product20,
  'images/veganBurger.jpg': product21,
  'images/chickenSandwich.jpg': product22,
  'images/margheritaPizza.jpg': product23,
  'images/dumplingStew.jpg': product24,
  'images/casesarSalad.jpg': product25,
  'images/chickenTacos.jpg': product26,
  'images/baconAndHamPizza.jpg': product27,
  'images/beefBurrito.jpg': product28,
  'images/carrotAndPotatoSoup.jpg': product29,
  'images/chickenNoodleSoup.jpg': product30,

};

export function ProductTypes() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [notificationID, setNotificationID] = useState("");
  const { addToCart } = useCart();

  const shortProducts = products.slice(0, 4);
  const navigate = useNavigate();

 

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {
      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID);
      await delay(1000);
      setIsLoaded(true);
    };
    getData();
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    backgroundColor: "#21211c",
    color: "#7e7e7c",
    padding: "0.5vh 1vw",
    '& .MuiBadge-badge': {
      backgroundColor: "#000000", 
      color: 'white',             
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, 
      fontSize: '14px',
      minWidth: '22px',
      height: '22px',
    },
  }));

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
    backgroundImage: `url(${landingImage})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  const { totalItems, totalCost, items } = useCart();
  
  const handleCardClick = (productId: number) => {
    navigate(`/meal-details/${productId}`);
  };

  
  

  const displayFood = (meal: any) => (
    
    <Grid item xs={8} sm={10} key={meal.id} sx={{ margin: "auto !important"}}>
      <CardActionArea onClick={() => handleCardClick(meal.id)}>
        <Card sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}, border: "1px solid #000", boxShadow: "none", justifyContent:  "space-between", alignItems: "center", margin: "auto"}}>
            <CardContent>
              <Typography gutterBottom variant="h6" className='day' sx={{ fontFamily: "Poppins", color: "#1F140B !important", textAlign: {xs: "center", md: "left"}}}>
                {meal.day}
              </Typography>

              <Typography gutterBottom variant="h6" className='subHeading' sx={{ fontSize: "32px !important", color: "#1F140B !important", textAlign: {xs: "center", md: "left"}}}>
                {meal.name}
              </Typography>

              <Typography gutterBottom variant="body2" className='mainText' color="text.secondary" sx={{ fontSize: "20px !important", width: "80%", textAlign: "left !important"}}>
                {meal.description}
              </Typography>

              {/* <Typography variant="body2" color="text.secondary">
                Ingredients: {meal.ingredients.join(", ")}
              </Typography> */}

              <Grid container mt={4} sx={{ display: "flex !important", flexDirection: "row !important", flexFlow: "row"}}>
                <Typography variant="body1" className="priceDetail" my={4} textAlign="left" justifySelf="left">
                <b>R{meal.price.toFixed(2)}</b>
                </Typography>

                {/* <Button variant='contained' className="secondButton" onClick={() => addToCart({ ...meal, quantity: 1 })}>
                  Add To Cart
                </Button> */}
              </Grid>

              <Typography gutterBottom variant="body2" className="mainText" color="text.secondary" sx={{ textAlign: {xs: "center", md: "left"}}}>
                {meal.classification.charAt(0).toUpperCase() + meal.classification.slice(1)}
              </Typography>
            </CardContent>
            
            <CardMedia
              component="img"
              image={imageMap[meal.image]}
              alt={meal.name}
              sx={{ padding: 1, display: "flex", width: {xs: "60%", lg: "10%"} }}
            />
        </Card>
      </CardActionArea>
      
  </Grid>
  );


  return (
    <>
      {isLoaded ? (
        <Grid container className='homeContainer'>

          <BackgroundContainer>
            <Typography className='Heading' variant="h1" sx={{ color: "#1F140B"}}>
                Cheers
                <br/>
                To Good Food And Even 
                <br/>
                Better Company
            </Typography>

            <Typography variant="body1" className="mainText" my={4}>Food that Feeds the Soul with a Contemporary Twist</Typography>
          </BackgroundContainer>

          <Grid container my={8}>
            <Typography variant="h2" className="subHeading">
              Welcome to Our Menu.
              <br/>
              Find below our list of delicious, high-quality meals and menu items available
            </Typography>

            <Typography variant="body1" className="mainText" margin="4vh auto 0 auto">
              To order, click on the menu item you like. You will be directed to a page
              <br/>
              where you can place the item in your cart as well as find more info about the item.
            </Typography>

          </Grid>

          <Grid container margin="0vh auto">
            <Grid container>
              <Grid item xs={10} sm={12} margin="auto">
                          {/* <Box my={8} flexDirection="column">
                            <Typography variant="h2" className="subHeading">
                              <span className="subHeading" style={{ backgroundColor: '#ffda3d'}}>Drinks</span>
                            </Typography>

                            <Grid container spacing={2} mt={4}>
                              {meals.drinks.map(displayFood)}
                            </Grid>
                          </Box> */}

                          <Box my={8} flexDirection="column">
                            <Typography variant="h2" className="subHeading">
                              <span className="subHeading" style={{ backgroundColor: '#ffda3d'}}>Mains</span>
                            </Typography>

                            <Grid container spacing={2} my={4}>
                              {meals.mains.map(displayFood)}
                            </Grid>
                          </Box>

                          {/* <Box my={8} flexDirection="column">
                            <Typography variant="h2" className="subHeading">
                              <span className="subHeading" style={{ backgroundColor: '#ffda3d'}}>Mains</span>
                            </Typography>

                            <Grid container spacing={2} my={4}>
                              {meals.mains.map(displayFood)}
                            </Grid>
                          </Box> */}

                          {/* <Box my={8} flexDirection="column">
                            <Typography variant="h2" className="subHeading">
                              <span className="subHeading" style={{ backgroundColor: '#ffda3d'}}>Desserts</span>
                            </Typography>

                            <Grid container spacing={2} my={4}>
                              {meals.desserts.map(displayFood)}
                            </Grid>
                          </Box> */}
                    </Grid>
                </Grid>
          </Grid>
        </Grid>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
