
type classification = "vegan" | "vegetarian" | "contains meat";

interface Product {
  id: number;
  day: string;
  name: string;
  description: string;
  ingredients: string[];
  image: string;
  price: number;
  classification: classification;
  allergens: string[];
}

const meals = {
  // drinks: <Product[]>[
  //   { id: 1, name: "Lemonade", description: "Refreshing lemon drink.", ingredients: ["water", "lemon", "sugar"], image: "images/lemonade.jpg", price: 19.99, classification: "vegan", allergens: "none" },
  //   { id: 2, name: "Iced Coffee", description: "Chilled coffee with milk.", ingredients: ["coffee", "milk", "ice"], image: "images/coffeeImage.jpg", price: 24.99, classification: "vegetarian", allergens: ["milk"] },
  //   { id: 3, name: "Green Smoothie", description: "Healthy green drink.", ingredients: ["spinach", "banana", "cucumber", "apple", "almond milk"], image: "greenSmoothie.jpg", price: 44.99, classification: "vegan", allergens: ["banana, milk, almond"] },
  //   { id: 4, name: "Mango Lassi", description: "Sweet mango yogurt drink.", ingredients: ["mango", "yogurt", "sugar"], image: "images/mangoLassi.jpg", price: 29.99, classification: "vegetarian", allergens: ["yoghurt, mango, milk"] },
  //   { id: 5, name: "Coconut Water", description: "Pure coconut water.", ingredients: ["coconut water"], image: "images/coconutWater.jpg", price: 27.99, classification: "vegan", allergens: ["coconut"] },
  // ],
  // desserts: <Product[]>[
  //   { id: 6, name: "Vegan Brownie", description: "Rich vegan chocolate brownie.", ingredients: ["flour", "cocoa", "coconut oil", "sugar"], image: "images/brownieImage.jpg", price: 24.99, classification: "vegan" },
  //   { id: 7, name: "Cheesecake", description: "Classic creamy cheesecake.", ingredients: ["cream cheese", "sugar", "eggs", "graham cracker crust"], image: "images/cheeseCake.jpg", price: 49.99, classification: "vegetarian" },
  //   { id: 8, name: "Apple Pie", description: "Traditional apple pie.", ingredients: ["apples", "flour", "butter", "sugar", "freshly prepared crust"], image: "images/applePie.jpg", price: 49.99, classification: "vegetarian" },
  //   { id: 9, name: "Mango Sorbet", description: "Refreshing mango sorbet.", ingredients: ["mango", "sugar", "water"], image: "images/mangoSorbet.jpg", price: 39.99, classification: "vegan" },
  //   { id: 10, name: "Chocolate Mousse", description: "Rich chocolate mousse.", ingredients: ["chocolate", "cream", "sugar"], image: "images/chocolateMousse.jpg", price: 46.99, classification: "vegetarian" },
  //   { id: 11, name: "Tiramisu", description: "Classic Italian dessert with coffee flavor.", ingredients: ["mascarpone", "coffee", "ladyfingers", "cocoa powder"], image: "images/tiramisu.jpg", price: 53.99, classification: "vegetarian" },
  //   { id: 12, name: "Fruit Salad", description: "Fresh seasonal fruit.", ingredients: ["strawberries", "blueberries", "melon", "grapes"], image: "images/fruitSalad.jpg", price: 22.99, classification: "vegan" },
  //   { id: 13, name: "Baklava", description: "Layered pastry dessert with nuts and honey.", ingredients: ["phyllo dough", "walnuts", "honey"], image: "images/baklava.jpg", price: 26.77, classification: "vegetarian" },
  // ],
  mains: <Product[]>[
    { id: 1, day: "Monday", name: "Basil Fried rice", description: "A delicious mix of fluffy rice stir-fried with tender chicken and vegetables, enhanced with savory soy sauce, garlic and ginger. Garnished with fresh green onion.", ingredients: ["Chicken", "Stir-fry", "Garlic", "Ginger", "Green onions"], image: "images/springRolls.jpg", price: 70, classification: "contains meat", allergens: ["Soy sauce", "Garlic", "Ginger"] },

    { id: 2, day: "Tuesday", name: "Authentic Bolognaise with homemade garlic bread", description: "A rich, hearty Bolgnese sauce made with ground beef, tomatoes and aromatic herbs. Served with a war, homemade garlic bread and infused with butter and fresh garlic.", ingredients: ["bread", "garlic", "butter"], image: "images/garlicBread.jpg", price: 70, classification: "contains meat", allergens: ["Garlic", "Tomatoes", "Bolgnese sauce", "Butter"] },

    { id: 3, day: "Wednesday", name: "Coconut Lamb Curry", description: "Tender pieces of lamb slow-cooked in a fragrant blend of spices and creamy coconut milk, creating a rich and flavourful curry. Served alongside smooth, buttery mashed potatoes.", ingredients: ["Lamb", "Butter", "Potatoes", "Coconut milk"], image: "images/nachosImages.jpg", price: 80, classification: "contains meat", allergens: ["coconut", "butter"] },

    { id: 4, day: "Thursday", name: "Grilled chicken with a Mediterranean couscous salad", description: "Tender lemon and herb grilled chicken. Served with fluffy couscous tossed with a roasted butternut squash, crumbled feta cheese and a medley of fresh herbs.", ingredients: ["Chicken", "Couscous", "Butternut squash", "Feta cheese", "Lemon"], image: "images/falafelBites.jpg", price: 85, classification: "contains meat", allergens: ["Dairy", "Cheese", "Lemon", "Butternut Squash"] },

    { id: 5, day: "Friday", name: "Classic Burger", description: "Our classic burger with lettuce, pickles, heirloom tomatoes, served with a side of fries. You have the option to choose between a Chicken, Beef or Mushroom Burger. We specifically suggest the Mushroom Burger for those with vegetarian dietary needs/restrictions.", ingredients: ["Burger Buns", "Lettuce", "Pickles", "Heirloom Tomatoes", "Patty(chicken or beef)", "Eggs", "Seasonings", "Dressing", "Olive Oil"], image: "images/hummusPlatter.jpg", price: 75, classification: "vegetarian", allergens: [ "Guacamole", "Dairy", "Jalapenos", "Tomatoes"] },
  ],
  // mains: <Product[]>[
  //   { id: 21, name: "Vegan Burger", description: "Plant-based burger made with the perfect blend of SOY and WHEAT protein and a perfect mix of spices.", ingredients: ["plant based patty", " creamy vegan mayonnaise", "lettuce", "tomato", "bun"], image: "images/veganBurger.jpg", price: 80.0, classification: "vegan" },
  //   { id: 22, name: "Grilled Chicken Sandwich", description: "Grilled chicken with lettuce and mayo.", ingredients: ["skinless chicken breast", "lettuce", "creamy mayo", "bun", "potato chips", "avocado"], image: "images/chickenSandwich.jpg", price: 55.90, classification: "contains" },
  //   { id: 23, name: "Margherita Pizza", description: "Classic pizza with mozzarella and basil.", ingredients: ["flour", "tomato", "mozzarella", "basil"], image: "images/margheritaPizza.jpg", price: 61.90, classification: "vegetarian" },
  //   { id: 24, name: "Dumplings with stew", description: "Steamed bread with beef stew and potatoes, carrots on the side.", ingredients: ["Flour", "yeast", "carrots", "beef", "potatoes"], image: "images/dumplingStew.jpg", price: 185.00, classification: "contains meat" },
  //   { id: 25, name: "Caesar Salad", description: "Classic Caesar with parmesan and croutons.", ingredients: ["lettuce", "parmesan", "croutons", "Caesar dressing"], image: "images/casesarSalad.jpg", price: 115.00, classification: "contain meat" },
  //   { id: 26, name: "Chicken Tacos", description: "Soft tacos with grilled chicken and salsa.", ingredients: ["chicken", "tortilla", "salsa", "lettuce"], image: "images/chickenTacos.jpg", price: 115.00, classification: "contains meat" },
  //   { id: 27, name: "Bacon and ham Pizza", description: "Mozarella cheese with pulled pork and bacon toppings", ingredients: ["Ham", "Bacon", "Cheddar", "Mozarella","Cream cheese base", "Secret sauce"], image: "images/baconAndHamPizza.jpg", price: 99.90, classification: "vegan" },
  //   { id: 28, name: "Beef Burrito", description: "Flour tortilla filled with beef and beans.", ingredients: ["beef", "beans", "rice", "tortilla"], image: "images/beefBurrito.jpg", price: 54.90, classification: "contains meat" },
  //   { id: 29, name: "Potato and carrots soup", description: "Delicious warm potato and carrots soup", ingredients: ["Carrots", "Potatoes"], image: "images/carrotAndPotatoSoup.jpg", price: 66.00, classification: "vegatarian" },
  //   { id: 30, name: "Chicken noodle soup", description: "Delicious warm chicken noodle soup", ingredients: ["Chicken", "Noodles"], image: "images/chickenNoodleSoup.jpg", price: 86.25, classification: "contains meat" },
  // ],
};

export default meals;
