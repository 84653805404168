// components/Home.js
import Maps from '../../components/maps/Maps';
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Divider, Grid, MenuItem, Select, styled, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import "./checkOut.css";
import contactImageBackground from "../../assets/images/contactImageBackground.png";
import { Link } from 'react-router-dom';
import contactFinalImage from "../../assets/images/contactFinalImage.png";
import contactPageBackground from "../../assets/images/contactPageBackground.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import contactLastImage from "../../assets/images/contactLastImage.png";
import { CustomTextField } from '../../App';
import * as React from 'react';

export function CheckOut() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  const [alignment, setAlignment] = React.useState('pick-up');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };


  return (
    <>
      {isLoaded ? (
        <>
            <Grid container>
                <Grid container flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
                    <Grid   
                        m={4}
                        container
                        spacing={0} 
                        sx={{
                            width: {xs: 300, md: 800} 
                        }}
                    >
                        <Typography variant="h1" className="subHeading" textAlign="left" sx={{ margin: "0vh !important"}}>
                            CHECKOUT POINT
                        </Typography>

                        <Typography variant="body1" className="mainText" textAlign="left" sx={{ margin: "0vh !important"}}>
                            Woo-hoo you're almost there! We are just missing some last info before you can enjoy your yummy meal.
                        </Typography>
                    </Grid>   
                </Grid>

                <Grid container flexDirection="column" justifyContent="center" alignItems="center">

                    <Grid   
                        m={4}
                        container
                        spacing={0} 
                        sx={{
                            width: {xs: 300, md: 800} 
                        }}
                    >
                        <Typography variant="h2" className="subHeading" textAlign="left" sx={{ margin: "0vh !important"}}>
                            Delivery Method
                        </Typography>

                        <Grid item xs={12}>
                            <ToggleButtonGroup
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="toggle buttons"
                                fullWidth
                            >
                                <ToggleButton value="pick-up" aria-label="pick-up">
                                    PICK-UP
                                </ToggleButton>

                                <ToggleButton value="delivery" aria-label="delivery">
                                    DELIVERY
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
     
                        <Grid item xs={6}>
                            <Select defaultValue="Pickup Point in Cape Town" fullWidth sx={{ borderRadius: "0vw !important", border: "2px solid #FFDA3D !important", "&:focus": {
                                border: "1px solid #FFDA3D !important"
                            }}}>
                            <MenuItem value="Home Address"> Home Address </MenuItem>
                            <MenuItem value="Work Address"> Work Address </MenuItem>
                            <MenuItem value="Address 3"> Address 3</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                            fullWidth
                            defaultValue="Delivery right to your door"
                            sx={{ borderRadius: "0vw !important", border: "1px solid #FFDA3D !important"}}
                            InputProps={{
                                readOnly: true,
                            }}
                            />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                    <Grid   
                        m={4}
                        container
                        spacing={0} 
                        sx={{
                            width: {xs: 300, md: 800} 
                        }}
                    >
                        <Typography variant="h2" className="subHeading" textAlign="left" sx={{ margin: "0vh !important"}}>
                            Recipient Information
                        </Typography>

                        <Grid item xs={12} display="flex" flexDirection="column">
                            <CustomTextField id="outlined-basic" label="Recipient Name" variant="outlined" />
                            <br/>
                            <CustomTextField id="outlined-basic" label="Recipient Mobile Number" variant="outlined" />
                            <br/>
                            <CustomTextField id="outlined-basic" label="Recipient Email Address" variant="outlined" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                    <Grid   
                        m={4}
                        container
                        spacing={0} 
                        sx={{
                            width: {xs: 300, md: 800} 
                        }}
                    >
                        <Typography variant="h2" className="subHeading" textAlign="left" sx={{ margin: "0vh !important"}}>
                            Delivery Information* (not applicable for Pickup orders)
                        </Typography>

                        <Grid item xs={12} display="flex" flexDirection="column">
                            <CustomTextField id="outlined-basic" label="Street Address e.g 6 Stardust Avenue" variant="outlined" />
                            <br/>
                            <CustomTextField id="outlined-basic" label="Complex / Building (Optional)" variant="outlined" />
                            <br/>
                            <CustomTextField id="outlined-basic" label="Surburb" variant="outlined" />
                            <br/>
                            <CustomTextField id="outlined-basic" label="City / Town" variant="outlined" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                    <Grid   
                        m={4}
                        container
                        spacing={0} 
                        sx={{
                            width: {xs: 300, md: 800} 
                        }}
                    >
                        <Typography variant="h2" className="subHeading" textAlign="left" sx={{ margin: "0vh !important"}}>
                            Payment Information
                        </Typography>

                        <Typography variant="body1" className="mainText" textAlign="left"  sx={{ margin: "0vh 0vh 3vh 0vh !important"}}>
                            We do/ do not accept cash at arrival. We do/ do not have a mobile card machine facilities.
                        </Typography>

                        <Grid item xs={12} display="flex" flexDirection="column">
                            <CustomTextField id="outlined-basic" label="Cardholder name" variant="outlined" />
                            <br/>
                            <CustomTextField id="outlined-basic" label="Card number" variant="outlined" />
                            <br/>

                            <Grid container spacing={2} display="flex" justifySelf="start" textAlign="left">
                                <Grid item xs={12} md={4}>
                                    <CustomTextField id="outlined-basic" label="Expiration date" variant="outlined" />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <CustomTextField id="outlined-basic" label="CVV" variant="outlined" />
                                </Grid>
                            </Grid>
                           
                            <br/>
                           
                        </Grid>
                    </Grid>

                    <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                        <Grid   
                            m={4}
                            container
                            spacing={0} 
                            sx={{
                                width: {xs: 300, md: 800} 
                            }}
                        >
                            <Button variant="outlined" className="secondButton" sx={{ width: {xs: "25vw !important", md: "initial"}}}>
                                Submit
                            </Button>
                        </Grid>
                        
                    </Grid>
                </Grid>

            </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
