import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { products } from '../../data/products';
import { useCart } from '../../context/cartContext';
import { Box, Button, CardActionArea, Chip, Divider, FormControl, Grid, InputLabel, MenuItem, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import './productDetails.css';
import { HashLink as Link } from 'react-router-hash-link';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import brownieImage from "../../assets/images/brownieImage.jpg"; 
import productDetailBackground from "../../assets/images/graphic/3.png"
import * as React from 'react';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import meals from '../../data/meals';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import product1 from '../../assets/images/lemonadeImage.jpg';
import product2 from '../../assets/images/coffeeImage.jpg';
import product3 from '../../assets/images/greenSmoothie.jpg';
import product4 from '../../assets/images/mangoLassi.jpg';
import product5 from '../../assets/images/coconutWater.jpg';
import product6 from '../../assets/images/brownieImage.jpg';
import product7 from '../../assets/images/cheeseCake.jpg';
import product8 from '../../assets/images/applePie.jpg';
import product9 from '../../assets/images/mangoSorbet.jpg';
import product10 from '../../assets/images/chocolateMousse.jpg';
import product11 from '../../assets/images/Images/classicBurger.jpeg';
import product12 from '../../assets/images/fruitSalad.jpg';
import product13 from '../../assets/images/baklava.jpg';
import product14 from '../../assets/images/bruschetta.jpg';
import product15 from '../../assets/images/stuffedMushrooms.jpg';
import product16 from '../../assets/images/mangoLassi.jpg';
import product17 from '../../assets/images/garlicBread.jpg';
import product18 from '../../assets/images/nachosImages.jpg';
import product19 from '../../assets/images/falafelBites.jpg';
import product20 from '../../assets/images/hummusPlatter.jpg';
import product21 from '../../assets/images/veganBurger.jpg';
import product22 from '../../assets/images/chickenSandwich.jpg';
import product23 from '../../assets/images/margheritaPizza.jpg';
import product24 from '../../assets/images/dumplingStew.jpg';
import product25 from '../../assets/images/casesarSalad.jpg';
import product26 from '../../assets/images/chickenTacos.jpg';
import product27 from '../../assets/images/baconAndHamPizza.jpg';
import product28 from '../../assets/images/beefBurrito.jpg';
import product29 from '../../assets/images/carrotAndPotatoSoup.jpg';
import product30 from '../../assets/images/chickenNoodleSoup.jpg';

const  imageMap: { [key: string]: string } = {
 'images/lemonade.jpg': product1,
  'images/coffeeImage.jpg': product2,
  'greenSmoothie.jpg': product3,
  'images/mangoLassi.jpg': product4,
  'images/coconutWater.jpg': product5,
  'images/brownieImage.jpg': product6,
  'images/cheeseCake.jpg': product7,
  'images/applePie.jpg': product8,
  'images/mangoSorbet.jpg': product9,
  'images/chocolateMousse.jpg': product10,
  'Images/classicBurger.jpeg': product11,
  'images/fruitSalad.jpg': product12,
  'images/baklava.jpg': product13,
  'images/bruschetta.jpg': product14,
  'images/stuffedMushrooms.jpg': product15,
  'images/springRolls.jpg': product16,
  'images/garlicBread.jpg': product17,
  'images/nachosImages.jpg': product18,
  'images/falafelBites.jpg': product19,
  'images/hummusPlatter.jpg': product20,
  'images/veganBurger.jpg': product21,
  'images/chickenSandwich.jpg': product22,
  'images/margheritaPizza.jpg': product23,
  'images/dumplingStew.jpg': product24,
  'images/casesarSalad.jpg': product25,
  'images/chickenTacos.jpg': product26,
  'images/baconAndHamPizza.jpg': product27,
  'images/beefBurrito.jpg': product28,
  'images/carrotAndPotatoSoup.jpg': product29,
  'images/chickenNoodleSoup.jpg': product30,

};

export function ProductDetails() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [notificationID, setNotificationID] = useState("");

  const shortProducts = products.slice(0, 4);
  const { addToCart } = useCart();

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {
      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID);
      await delay(1000);
      setIsLoaded(true);
    };
    getData();
  }, []);

  const [quantity, setQuantity] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setQuantity(event.target.value);
  };

   const [selectedValue, setSelectedValue] = React.useState('a');

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleSelection,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    minHeight: '60vh',
    backgroundImage: `url(${productDetailBackground})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'left',
    width: '80%',
    margin: '4vh auto',
    padding: '0 5vh 0 5vh',
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  const { productId } = useParams<{ productId: string }>();
  const allProducts = Object.values(meals).flat();
  const product = allProducts.find((p) => p.id === Number(productId));

  if (!product) {
    return (
      <Typography variant="h4" color="error" align="center">
        Product not found
      </Typography>
    );
  }

  const allergenList = Array.isArray(product.allergens)
  ? product.allergens
  : product.allergens === "none"
  ? []
  : [product.allergens]; 

  return (
    <>
      {isLoaded ? (

        <Grid container justifyContent="center" alignItems="center" p={4}>
          
          <Grid container>
            <Link to="/menu" style={{ fontSize: "2rem !important"}}>
              <ArrowBackIcon />
            </Link>
          </Grid>

          <Card sx={{ maxWidth: 1000, display: "flex", flexDirection: "column", boxShadow: "none" }}>
            <CardMedia
              component="img"
              image={imageMap[product.image]}
              alt={product.name}
            />

            <Grid>
              <CardContent>
                <Typography gutterBottom variant="h5" className="subHeadingDetail" textAlign="left" justifySelf="left" mt={4} mb={0}>
                  {product.name}
                </Typography>

                <Typography variant="body2" className="mainText" textAlign="left" justifySelf="left" sx={{ width: "80%"}}>
                  {product.description}
                </Typography>
              </CardContent>
              
              <br/>
            </Grid>
            
            <Grid container mt={4} justifyContent="center" alignItems="center" sx={{ display: "flex !important", flexDirection: "row !important", flexFlow: "row"}}>
              <Box sx={{ display: "flex", flexDirection: "row !important", alignItems: "center", textAlign: "left !important"}}>
                <Typography variant="body1" className="priceDetail" my={4} textAlign="left" justifySelf="left" m={2}>
                  Price: R{product.price}
                </Typography>

                <Button variant='contained' className="secondButton" sx={{ height: "7vh !important"}} onClick={() => addToCart({ ...product, quantity: 1 })}>
                  Add To Cart
              </Button>
              </Box>
             
            </Grid>

            <Divider sx={{ width: "100%", margin: "2vh auto", color: "#000 !important" }} />
          </Card>

          <Grid container flexDirection="column">
            <Typography variant="body1" className="mainText" sx={{ display: "flex", flexDirection: "column !important"}}>
              <b>Allergens:
                {allergenList.length > 0 ? (
                  <Box my={2}>
                    {allergenList.map((allergen, index) => (
                      <Chip
                        label={allergen}
                        color="error"
                        variant="outlined"
                        key={index}
                        sx={{ margin: 2}}
                      />
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No allergens.
                  </Typography>
                )}
              </b> 
            </Typography>

            <Typography variant="body1" className="mainText" sx={{ display: "flex", flexDirection: "column !important"}} margin="auto">
              <b>Key Ingredients: 
                <Box my={2}>
                  {product.ingredients.map((ingredient, index) => (
                    <Chip label={ingredient} key={index} sx={{ margin: 2}} />
                  ))}
                </Box>
              </b> 
            </Typography>
          </Grid>

          <BackgroundContainer>
            <Typography className='subHeadingDetail' variant="body1" sx={{ color: "#f9f9f9", margin: 0, textAlign: "left", }}>
              Craving more?
            </Typography>

            <Typography className='mainText' variant="body1" sx={{ color: "#f9f9f9", width: "60%"}}>
              Revisit our Menu Page to add extra goodness to your order. You don't have to restrict yourself to one day only!
            </Typography>

            <Grid container>
              <Link to="/menu" style={{ margin: "2vh 0"}}>
                <Button className="secondButton" 
                  sx={{ color: "#f9f9f9 !important", 
                    textTransform: "capitalize", borderRadius: "0.1vw",
                    height: "6vh", 
                      "&: hover": {
                        backgroundColor: "#806044", 
                        fontWeight: 600, color: "white", 
                      } 
                  }}
                >
                   Menu
                </Button>
              </Link>
            </Grid>
          </BackgroundContainer>

        </Grid>

      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
      </div>
      )}
    </>
  );
}
