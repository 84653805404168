// src/data/products.ts
export const products = [
    { id: 1, name: 'Dessert of the week', price: 65.00, image: 'brownieImage.jpg', size: '100ml', category: 'Body Oils', brand: 'Alexander & Co'     },
    { id: 2, name: 'Mystique body oil', price: 150.00, image: 'bodyOil2.jpg', size: '100ml', category: 'Body Oils', brand: 'Mystique'      },
    { id: 3, name: 'Naturo Body Oil', price: 175.00, image: 'bodyOil3.jpg', size: '150ml', category: 'Body Oils', brand: 'Naturo'      },
    { id: 4, name: 'THRVY Body Oil', price: 225.00, image: 'bodyOil7.jpg', size: '100ml', category: 'Body Oils', brand: 'THRVY'     },
    { id: 5, name: 'Lador Morrocan Argan Oil', price: 275.00, image: 'bodyOil6.jpg', size: '200ml', category: 'Body Oils', brand: 'Lador'      },
    { id: 6, name: 'Archive Body Oil', price: 200.00, image: 'archiveBodyOil.jpg', size: '150ml', category: 'Body Oils', brand: 'Archive'     },

    { id: 7, name: 'Rose Gold Cosmetics Range', price: 800.00, image: 'roseGoldCosmetics.jpg', size: 'Collection', category: 'Cosmetics', brand: 'Black Butterfly'      },
    { id: 8, name: 'Rose Gold Cosmetics Range 2', price: 800.00, image: 'roseGoldCosmetics2.jpg', size: 'Collection', category: 'Cosmetics', brand: 'Black Butterfly'     },
    { id: 9, name: 'NATURAL lotion', price: 150.00, image: 'naturalLotion.jpg', size: '480ml', category: 'Cosmetics', brand: 'Natural'     },
    { id: 10, name: 'BIRGITH lotion', price: 250.00 , image: 'birgithLotion.jpg', size: '480ml', category: 'Cosmetics', brand: 'Birgith'   },
    { id: 11, name: 'SUAVE lotion', price: 119.99 , image: 'blackButterflyLotion.jpg', size: '750ml', category: 'Cosmetics', brand: 'Suave' },
    { id: 12, name: 'ORDINARY cosmetics', price: 699.99 , image: 'ordinaryLotionRange.jpg', size: 'Collection', category: 'Cosmetics', brand: 'Ordinary' },

    { id: 13, name: 'Black Butterfly eye shadow', price: 299.99 , image: 'TFEyeCare.jpg', size: '2g', category: 'Eye shadow', brand: 'Black Butterfly' },
    { id: 14, name: 'RXSE eye shadow', price: 199.99 , image: 'eyeShadow.jpg', size: '2g', category: 'Eye shadow', brand: 'RXSE' },
    { id: 15, name: 'FUXT makeup collection', price: 350.00 , image: 'fuxtEyeCare.jpg', size: 'Collection', category: 'Make Up', brand: 'FUXT' },
    { id: 16, name: 'LAMER makeup collection', price: 499.99 , image: 'lamerEyeRange.jpg', size: 'Collection', category: 'Make Up', brand: 'Lamer' },
    { id: 17, name: 'NARS makeup collection', price: 599.99 , image: 'narsEyeRange.jpg', size: 'Collection', category: 'Make Up', brand: 'NARS' },
    { id: 18, name: 'HOZER makeup collection', price: 499.99 , image: 'hoverEyeRange.jpg', size: 'Collection', category: 'Make Up ', brand: 'Hozer' },

    { id: 19, name: 'ARTHUR PEREIRA Hair Range', price: 399.99 , image: 'arthurPereiraHairRange.jpg', size: 'Collection', category: 'Hair', brand: 'Arthur Pereira' },
    { id: 20, name: 'APOTHECARY Hair Shampoo', price: 199.99 , image: 'apothecaryHairShampoo.jpg', size: '330ml', category: 'Hair', brand: 'Apothecary' },
    { id: 21, name: 'PANTENE Hair Conditioner', price: 99.99 , image: 'panteneHairConditioner.jpg', size: '750ml', category: 'Hair', brand: 'Pantene' },
    { id: 22, name: 'LUMIN Hair Range', price: 299.99 , image: 'luminHairRange.jpg', size: 'Collection', category: 'Hair', brand: 'Lumin' },
    { id: 23, name: 'FRONTROW Hair Conditioner', price: 159.99 , image: 'frontRowhairConditioner.jpg', size: '240ml', category: 'Hair', brand: 'Frontrow' },
    { id: 24, name: 'CUROLOGY Hairspray', price: 129.99 , image: 'curologyHairspray.jpg', size: '750ml', category: 'Hair', brand: 'Curology' },
];
