import { Authenticator } from '@aws-amplify/ui-react';
import { Login } from './components/auth/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Home } from './pages/home/home';
import { RequireAuth } from './components/auth/RequireAuth';
import { Layout } from './layout/Layout';
import { AppRoute, CustomRoutes, SettingRoutes } from './routes';
import { NotFoundPage } from './pages/errors/400';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Container, styled, TextField } from '@mui/material';
import { CartProvider } from './context/cartContext';
import Cart from './pages/cart/cart';


export const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',    
        borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '1px solid #000000',          
        },
        '&:hover fieldset': {
            border: '1px solid #D3B76B', 
        },
        '&.Mui-focused fieldset': {
            border: '2px solid #D3B76B',     
        },
    },
    '& .MuiInputLabel-root.Mui-hover': {
        color: '#D3B76B',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#D3B76B',                
    }
});


function MyRoutes() {
    return (
        <>
        <CartProvider>
            <ReactNotifications />
            
            <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/cart" element={<Cart />} />

                    {CustomRoutes.map((cRo: AppRoute) => (
                                    <Route
                                        key={cRo.DisplayName}
                                        path={cRo.Navigation}
                                        element={cRo.Protected ? (
                                            <RequireAuth>
                                                <cRo.Component />
                                            </RequireAuth>
                                        ) : (
                                            <cRo.Component />
                                        )}
                                    />
                    ))}

                    {SettingRoutes.map((setting: AppRoute) => (
                                    <Route
                                        key={setting.DisplayName}
                                        path={setting.Navigation}
                                        element={setting.Protected ? (
                                            <RequireAuth>
                                                <setting.Component />
                                            </RequireAuth>
                                        ) : (
                                            <setting.Component />
                                        )}
                                    />
                    ))}

                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<NotFoundPage />} />
                    </Route>
                  </Routes>

            </BrowserRouter>
        </CartProvider>
        </>
    );
}

function App() {
    return (
        <Authenticator.Provider>
            <MyRoutes />
        </Authenticator.Provider>
    );
}

export default App;
