import { useCart } from '../../context/cartContext';
import { Avatar, Box, Button, ButtonBase, ButtonGroup, CardActionArea, Chip, Grid, IconButton, Paper, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import homeImage from "../../assets/images/homeImage.svg";
import categoryImage1 from "../../assets/images/categoryImage1.svg";
import categoryImage2 from "../../assets/images/categoryImage2.svg";
import categoryImage3 from "../../assets/images/categoryImage3.svg";
import homeImage2 from "../../assets/images/homeImage2.png";
import teamImage1 from "../../assets/images/teamImage1.svg";
import teamImage2 from "../../assets/images/teamImage2.svg";
import NetflixCarousel from '../carousel/carousel';
import { MobileProductView } from '../mobileProductView/mobileProductView';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { HashLink as Link } from 'react-router-hash-link';
import mainPageImage from "../../assets/mainMealImage.jpg";
import spiceBowl from "../../assets/images/spiceBowl.jpg.jpg";
import { styled } from '@mui/material/styles';
import mealImage from "../../assets/images/mealPhoto.jpg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConttactSection from '../../components/looks/ContactSection';
import orderingImage from "../../assets/images/orderingImage.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import meals from "../../data/meals";

//Images
import product1 from '../../assets/images/applePie.jpg';
import product2 from '../../assets/images/baconAndHamPizza.jpg';
import product3 from '../../assets/images/baklava.jpg';
import product4 from '../../assets/images/beefBurrito.jpg';
import product5 from '../../assets/images/brownieImage.jpg';
import product6 from '../../assets/images/bruschetta.jpg';
import product7 from '../../assets/images/carrotAndPotatoSoup.jpg';
import product8 from '../../assets/images/casesarSalad.jpg';
import product9 from '../../assets/images/cheeseCake.jpg';
import product10 from '../../assets/images/chickenNoodleSoup.jpg';
import product11 from '../../assets/images/chickenSandwich.jpg';
import product12 from '../../assets/images/chickenTacos.jpg';
import product13 from '../../assets/images/chocolateMousse.jpg';
import product14 from '../../assets/images/coconutWater.jpg';
import product15 from '../../assets/images/coffeeImage.jpg';
import product16 from '../../assets/images/dumplingStew.jpg';
import product17 from '../../assets/images/falafelBites.jpg';
import product18 from '../../assets/images/fruitSalad.jpg';
import product19 from '../../assets/images/garlicBread.jpg';
import product20 from '../../assets/images/greenSmoothie.jpg';
import product21 from '../../assets/images/hummusPlatter.jpg';
import product22 from '../../assets/images/lemonadeImage.jpg';
import product23 from '../../assets/images/mangoLassi.jpg';
import product24 from '../../assets/images/mangoSorbet.jpg';
import product25 from '../../assets/images/margheritaPizza.jpg';
import product26 from '../../assets/images/nachosImages.jpg';
import product27 from '../../assets/images/springRolls.jpg';
import product28 from '../../assets/images/stuffedMushrooms.jpg';
import product29 from '../../assets/images/tiramisu.jpg';
import product30 from '../../assets/images/veganBurger.jpg';



export function OnlineOrders() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [notificationID, setNotificationID] = useState("")
    const { addToCart } = useCart();
    const isTabletOrAbove = useMediaQuery('(min-width:1024px)');
    const { totalItems, totalCost, items } = useCart();

    const navigate = useNavigate();
    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [status, setStatus] = useState("green"); // Can be "red", "yellow", or "green"

    useEffect(() => {
        const getData = async () => {
    
          setNotificationID(Store.addNotification({
            title: "Wonderful!",
            message: "teodosii@react-notifications-component",
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
    
          Store.removeNotification(notificationID)
          await delay(1000);
          setIsLoaded(true)
    
        }
        getData()
    }, [])


  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${spiceBowl})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',

    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '90%',
    maxHeight: '90%',
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    backgroundColor: "#21211c",
    color: "#7e7e7c",
    padding: "0.5vh 1vw",
    '& .MuiBadge-badge': {
      backgroundColor: "#000000", 
      color: 'white',             
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, 
      fontSize: '14px',
      minWidth: '22px',
      height: '22px',
    },
  }));

  const  imageMap: { [key: string]: string } = {
    'applePie.jpg': product1,
    'baconAndHamPizza.jpg': product2,
    'baklava.jpg': product3,
    'beefBurrito.jpg': product4,
    'brownieImage.jpg': product5,
    'bruschetta.jpg': product6,
    'carrotAndPotatoSoup.jpg': product7,
    'casesarSalad.jpg': product8,
    'cheeseCake.jpg': product9,
    'chickenNoodleSoup.jpg': product10,
    'chickenSandwich.jpg': product11,
    'chickenTacos.jpg': product12, 
    'chocolateMousse.jpg': product13,
    'coconutWater.jpg': product14,
    'coffeeImage.jpg': product15,
    'dumplingStew.jpg': product16,
    'falafelBites.jpg': product17,
    'fruitSalad.jpg': product18, 
    'garlicBread.jpg': product19,
    'greenSmoothie.jpg': product20,
    'hummusPlatter.jpg': product21, 
    'lemonadeImage.jpg': product22,
    'mangoLassi.jpg': product23,
    'mangoSorbet.jpg': product24, 
    'margheritaPizza.jpg': product25,
    'nachosImages.jpg': product26,
    'springRolls.jpg': product27,
    'stuffedMushrooms.jpg': product28,
    'tiramisu.jpg': product29,
    'veganBurger.jpg': product30,
  };
  
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const displayFood = (meal: any) => (
    <Grid item xs={12} sm={6} key={meal.id}>
      <Card>
        <CardMedia
          component="img"
          height="140"
          image={imageMap[meal.image]}
          alt={meal.name}
        />
        <CardContent>
          <Typography variant="h6" className='mealName'>{meal.name}</Typography>

          <Typography variant="body2" className='mealDescription'>
            {meal.description}
          </Typography>

          {/* <Typography variant="body2" color="text.secondary">
            Ingredients: {meal.ingredients.join(", ")}
          </Typography> */}

          <Typography variant="body1" color="text.primary">
            R{meal.price.toFixed(2)}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {meal.classification.charAt(0).toUpperCase() + meal.classification.slice(1)}
          </Typography>
        </CardContent>
      </Card>
  </Grid>
  );

    return (
        <>
        {isLoaded ? (
          <>
       <Grid container className='homeContainer'>

            <Grid item xs={12}>
                <Card sx={{ boxShadow: "none"}}>
                        <CardMedia
                        component="img"
                        image={orderingImage}
                        alt="green iguana"
                        sx={{ height: {xs: "200", lg: "500"}}}
                        />
                </Card> 
            </Grid>

            <Grid container flexDirection="column">
                <Typography gutterBottom variant='h1' className='pageHeading'>ONLINE ORDERING</Typography>

                <Typography gutterBottom variant='body1'>You can order online! Browse our menu items and choose what you'd like to order from us.</Typography>

                <Chip
                    icon={
                        <div
                            style={{
                                display: "inline-block",
                                width: 10,
                                height: 10,
                                backgroundColor: "green",
                                borderRadius: "50%",
                                marginRight: 4
                            }}
                        ></div>
                    }

                    label="Accepting Orders"
                    variant="outlined"
                    sx={{ width: "10%", margin: "2vh auto" }}
                />

            </Grid>
            
            <Grid container flexDirection="column">
                <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled button group"
                    sx={{ margin: "2vh auto"}}
                >
                    <Button variant="outlined" size="large">Pickup</Button>
                    <Button variant="outlined" size="large">Delivery</Button>
                </ButtonGroup>

                <Typography variant='body1' gutterBottom> 
                    <FontAwesomeIcon icon={faClock} /> Pickup time: up to 30 minutes.
                </Typography>

                <Grid container justifyContent="center" alignItems="center" flexDirection="row" sx={{ margin: "2vh auto"}}>
                    <Typography variant='body1' mr={4} gutterBottom>
                        <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 5 }} />Pickup Address: 500 Terry Francine Street, San Francisco, CA 1560
                    </Typography> 
                    
                    <React.Fragment>
                        <Button variant="text" size="small" sx={{ color: "#000000", textDecoration: "underline"}} onClick={handleClickOpen}>
                            Change
                        </Button>
                        <Dialog
                            fullScreen={fullScreen}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogContent sx={{ minWidth: {xs: 300, sm: 500} }}>
                                <DialogContentText>
                                    <TextField fullWidth id="outlined-basic" placeholder="500 Terry Francine Street, San Francisco, CA 1560" variant="outlined" />
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button autoFocus onClick={handleClose} sx={{ color: "red"}}>
                                    Cancel
                                </Button>

                                <Button variant='outlined' onClick={handleClose} autoFocus sx={{ backgroundColor: "#564337", color: "#fff", "&: hover": {
                                    backgroundColor: "#d6cec3",
                                    color: "#000",
                                    border: "none"
                                }}}>
                                    SAVE
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                </Grid>

                <Grid container>
                    <Grid item xs={12} sm={12}>
                      <TabContext value={value}>
                        <Stack direction="column">
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="product tabs" variant="fullWidth">
                              <Tab label="Appetizers" value="1" />
                              <Tab label="Mains" value="2" />
                              <Tab label="Desserts" value="3" />
                              <Tab label="Drinks" value="4" />
                            </TabList>
                          </Box>

                          <Box sx={{ padding: 3 }}>
                            {/* <TabPanel value="1">
                              <Grid container spacing={2}>
                                {meals.appetizers.map(displayFood)}
                              </Grid>
                            </TabPanel> */}

                            <TabPanel value="2">
                              <Grid container spacing={2}>
                                {meals.mains.map(displayFood)}
                              </Grid>
                            </TabPanel>

                            <TabPanel value="3">
                              <Grid container spacing={2}>
                                {/* {meals.desserts.map(displayFood)} */}
                              </Grid>
                            </TabPanel>

                            <TabPanel value="4">
                              <Grid container spacing={2}>
                                {/* {meals.drinks.map(displayFood)} */}
                              </Grid>
                            </TabPanel>
                          </Box>
                        </Stack>
                      </TabContext>
                    </Grid>

                    <Grid item xs={12}>
                        <IconButton color="inherit" onClick={() => { navigate('/cart'); handleClose(); }}>
                            <StyledBadge badgeContent={totalItems}>
                                View Cart
                            </StyledBadge>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            
            <ConttactSection />
            
       </Grid>
       </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>


  );
}
