// components/Home.js
import Maps from '../../components/maps/Maps';
import { CircleLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Divider, Grid, MenuItem, Select, styled, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import "./orderDetails.css";
import contactImageBackground from "../../assets/images/contactImageBackground.png";
import { Link } from 'react-router-dom';
import contactFinalImage from "../../assets/images/contactFinalImage.png";
import contactPageBackground from "../../assets/images/contactPageBackground.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import contactLastImage from "../../assets/images/contactLastImage.png";
import { CustomTextField } from '../../App';
import * as React from 'react';
import ConttactSection from '../../components/looks/ContactSection';
import orderDetails from "../../assets/images/orderDetails.png";

export function OrderDetails() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  const [alignment, setAlignment] = React.useState('pick-up');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };

  const BackgroundContainerS = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '40vh',
    backgroundImage: `url(${orderDetails})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',
    marginBottom: "4vh",
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });



  return (
    <>
      {isLoaded ? (
        <>
          <Grid container flexDirection="column">
            <Grid container flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
              <Grid container spacing={0} 
                m={4}
                sx={{
                  width: { xs: 300, md: 800 } 
                }}
              >
                <Typography variant="h1" className="subHeading" textAlign="left" sx={{ margin: "0vh !important"}}>
                  THANK YOU for shopping with us!
                </Typography>

                <Typography variant="body1" className="mainText" textAlign="left" sx={{ margin: "0vh !important"}}>
                  Your order is awaiting payment, please find your order info below and proceed with checking out.
                </Typography>
              </Grid>   
            </Grid>

            <Grid container flexDirection="column" justifyContent="center" alignItems="center">
              <Grid container spacing={0} 
                m={4}
                sx={{
                  width: { xs: 300, md: 800 } 
                }}
              >
                <Typography variant="h2" className="mainText" textAlign="left" sx={{ margin: "0vh !important", fontWeight: 500}}>
                  ORDER SUMMARY
                </Typography>

                <Grid item xs={12} display="flex" flexDirection="column">
                  <br/>

                  <Typography variant="body1" className="mainText" textAlign="left" p={1} sx={{ border: "1px solid #000"}}>
                    Number of items: 
                  </Typography>

                  <br/>

                  <Typography variant="body1" className="mainText" textAlign="left" p={1} sx={{ border: "1px solid #000"}}>
                    Delivery fee:
                  </Typography>

                  <br/>
                </Grid>

                <Grid item xs={12} justifySelf="end">
                  <Typography variant="body1" className="mainText" textAlign="left" display="flex" justifySelf="end" p={1} sx={{ backgroundColor: "#ffe67d", width: "40%"}}>
                    Total:
                  </Typography>
                </Grid>

                <Grid item xs={12} my={6}>
                  <Button className="secondButton" sx={{width: "100% !important"}}>
                    PAY WITH CARD
                  </Button>                  
                </Grid>
              </Grid>
            </Grid>

            <Grid container flexDirection="column" 
              justifyContent="center" alignItems="center"
            >
              <Grid container spacing={0}   
                m={4}
                sx={{
                  width: {xs: 300, md: 800} 
                }}
              >

                <Typography variant="h2" className="mainText" textAlign="left" sx={{ margin: "2vh 0vh !important", fontWeight: 500}}>
                  YOUR ORDER WILL ARRIVE:
                </Typography>     

                <Grid item p={1} xs={12} display="flex" flexDirection="column" 
                  sx={{ border: "1px solid #000"}}
                >
                  
                  <br/>

                  <Typography variant="body1" className="mainText" textAlign="left">
                    Monday, 04 November 2024
                  </Typography>

                  <br/>

                  <Typography variant="body1" className="mainText" textAlign="left">
                    Delivery - Home Address
                  </Typography>

                  <br/>

                  <Typography variant="body1" className="mainText" textAlign="left">
                    18:00PM - 20:0OPM
                  </Typography>

                  <br/>

                </Grid>
              </Grid>
            </Grid>

            <Grid container margin="auto" sx={{ width: {xs: "100%", md: "50%"} }}>
               <ConttactSection />
            </Grid>

            <Grid container justifyContent='center'>
              <Grid item xs={10}>
                <BackgroundContainerS>
                </BackgroundContainerS>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> 
          </div>
        </div>
      )}
    </>
  );
}
