// components/Home.js
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './faq.css';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardMedia, Container, Grid, Typography } from '@mui/material';
import faqBackgroundImage from "../../assets/images/Images/faqBackgroundImage.jpg";
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToysOutlined } from '@material-ui/icons';

export function FAQ() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])

  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${faqBackgroundImage})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  return (
    <>
      {isLoaded ? (
        <Grid container>
          <BackgroundContainer>
            <Grid container my={4} p={8} sx={{ backgroundColor: "#D9D9D9", display: "flex", flexDirection: "column"}}>
              <Typography variant="h1" className='subHeading' sx={{ fontSize: "70px !important"}} gutterBottom>
                FAQ's
              </Typography>

              <Typography variant="body1" className='mainText' gutterBottom>
                Here are some frequently asked questions about Comfort Crumbs.
                <br/>
                If you have any further questions, please do not hesitate to contact us.
              </Typography>
            </Grid>

            <Grid container className='faqContainer'>
              <Grid container>
                  <Container className='faqAccordionContainer'>
                    <div className='faqAccordion-con' style={{ padding: "0 1vw"}}>
                      <Accordion sx={{ marginBottom: "2vh", border: "3px solid #000000" }}>
                        <AccordionSummary
                          className='faqAccordionHeader'
                          expandIcon={<FontAwesomeIcon icon={faCaretDown} size="2x" color="#000000" />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="h6" sx={{ fontSize: { xs: 15, sm: 16, md: 18, lg: 20, xl: 20 } }}>
                              Where are you based and service to?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className='faqAccordionBody' >
                          <Typography className='accordion-body' variant="body1" sx={{ fontSize: { xs: 14, sm: 16, md: 16, lg: 17, xl: 19 } }}>
                            Container solutions offer remarkable versatility. From creating mobile offices to pop-up shops, they provide cost-effective and customizable spaces that adapt to your business needs.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ marginBottom: "2vh", border: "3px solid #000000" }}>
                        <AccordionSummary
                          className='faqAccordionHeader'
                          expandIcon={<FontAwesomeIcon icon={faCaretDown} size="2x" color="#000000" />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                              Can we refund a purchase?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className='faqAccordionBody' >
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                            Absolutely. Our container solutions are fully customizable. You can choose colors, branding, and interior layouts that align perfectly with your brand's identity.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ marginBottom: "2vh", border: "3px solid #000000" }}>
                        <AccordionSummary
                          className='faqAccordionHeader'
                          expandIcon={<FontAwesomeIcon icon={faCaretDown} size="2x" color="#000000" />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                            Is there a person limit for the dinner parties?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className='faqAccordionBody' >
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                            Yes, container solutions are eco-friendly alternatives. They repurpose shipping containers, reducing waste and supporting sustainable construction practices.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion sx={{ marginBottom: "2vh", border: "3px solid #000000" }}>
                        <AccordionSummary
                          className='faqAccordionHeader'
                          expandIcon={<FontAwesomeIcon icon={faCaretDown} size="2x" color="#000000" />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography variant="h6" className='accordion-header' sx={{ fontSize: { xs: 14, sm: 17, md: 18, lg: 20, xl: 20 } }}>
                            How long do orders take to arrive?
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className='faqAccordionBody' >
                          <Typography variant="body1" className='accordion-body' sx={{ fontSize: { xs: 14, sm: 17, md: 17, lg: 19, xl: 19 }, padding: 2 }}>
                            Yes, container solutions are eco-friendly alternatives. They repurpose shipping containers, reducing waste and supporting sustainable construction practices.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Container>
              </Grid>
            </Grid>
          </BackgroundContainer>

          <Grid container my={4} flexDirection="column">
            <Typography variant='h4' className="subHeading">Shipping & Refunds</Typography>

            <Grid container margin="auto">
              <Grid item xs={10} md={10} margin="auto">
                <Typography gutterBottom variant="body1" className="mainText">
                  We have a delivery option available at a set fee that 
                  <br/>will be added to your order total at the end of checkout
                </Typography>
              </Grid>
            </Grid>

           

            <Grid container margin="auto">
              <Grid item xs={10} md={10} margin="auto">
                <Typography mt={4} gutterBottom variant="body1" className="mainText">
                  We mainly deliver to areas within the larger Cape Town area.
                  <br/>
                  <br/>
                  We have a strict no returns policy, as the products are perishable.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container my={8} flexDirection="column">

            <Grid container>
              <Grid item xs={10} md={10} margin="auto">
                <Typography variant="h2" className="subHeading">
                  How would you like to receive your order?
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={9} md={10} margin="auto">
                <Typography variant="body1" className="mainText" sx={{ margin: "auto", textAlign: "center"}}>
                  There are two methods available to choose from when receiving your order:
                </Typography>
              </Grid>
            </Grid>

              
          
            <Grid container justifyContent="space-around" sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}}}>
              <Grid item flexDirection="column">
                <Card sx={{ minHeight: 400, maxWidth: 300, boxShadow: "none", backgroundColor: "#D9D9D9", margin: "2vh auto" }}>
                  <Typography variant="body1" className="mainText" sx={{ border: "3px solid #000000", margin: "1vw"}}>Pickup</Typography>

                  <Typography variant="body1" className="mainText" p={4}>
                    Pickup from:
                    <br/>
                    The Sea Point Promenade parking lot, closest to the Lighthouse/mini-golf.
                    <br/>
                    <br/>
                    Timeslot: 16:30 - 17:30
                  </Typography>
                </Card>
              </Grid>

              <Grid item flexDirection="column">
                <Card sx={{ minHeight: 400, maxWidth: 300, boxShadow: "none", backgroundColor: "#D9D9D9", margin: "2vh auto" }}>
                  <Typography variant="body1" className="mainText" sx={{ border: "3px solid #000000", margin: "1vw"}}>Delivery</Typography>

                  <Typography variant="body1" className="mainText" p={4}>
                    Delivery straight to your door
                    <br/>
                    A set delivery fee of R50 will be charged.
                    <br/>
                    <br/>
                    Delivery between: 18:00 - 20:00
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8} margin="auto">
            <Typography variant="h4" className="subHeading">Payment</Typography>

            <Typography variant="body1" className="mainText">We do not accept payments via cash, card or cheque on delivery or pickup. All orders and payments will be processed online at checkout.</Typography>
          </Grid>

          <Grid item xs={8} margin="8vh auto">
            <Typography variant="h4" className="subHeading">Dietary restrictions</Typography>

            <Typography variant="body1" className="mainText">At Comfort Crumbs we try our best to design an inclusive menu with a wide array of option, flavours and ingredients. Our menu features both vegetarian and meat based meals and will be noted where applicable for easy navigation. Any additional food allergies/possible allergens will also be noted in a menu item's description.</Typography>
          </Grid>
        </Grid>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )}
    </>
  );
}
