import { useCart } from '../../context/cartContext';
import { Box, Button, ButtonBase, CardActionArea, Divider, Grid, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import homeImage from "../../assets/images/homeImage.svg";
import categoryImage1 from "../../assets/images/categoryImage1.svg";
import categoryImage2 from "../../assets/images/categoryImage2.svg";
import categoryImage3 from "../../assets/images/categoryImage3.svg";
import homeImage2 from "../../assets/images/homeImage2.png";
import teamImage1 from "../../assets/images/teamImage1.svg";
import teamImage2 from "../../assets/images/teamImage2.svg";
import NetflixCarousel from '../carousel/carousel';
import { MobileProductView } from '../mobileProductView/mobileProductView';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { HashLink as Link } from 'react-router-hash-link';
import mainPageImage from "../../assets/mainMealImage.jpg";
import spiceBowl from "../../assets/images/spiceBowl.jpg.jpg";
import { styled } from '@mui/material/styles';
import mealImage from "../../assets/images/mealPhoto.jpg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConttactSection from '../../components/looks/ContactSection';

export function ShippingRefunds() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [notificationID, setNotificationID] = useState("")
    const { addToCart } = useCart();
    const isTabletOrAbove = useMediaQuery('(min-width:1024px)');

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    useEffect(() => {
        const getData = async () => {
    
          setNotificationID(Store.addNotification({
            title: "Wonderful!",
            message: "teodosii@react-notifications-component",
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
    
          Store.removeNotification(notificationID)
          await delay(1000);
          setIsLoaded(true)
    
        }
        getData()
    }, [])


  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${spiceBowl})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',

    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '90%',
    maxHeight: '90%',
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));
  

    return (
        <>
        {isLoaded ? (
          <>
            <Grid container className='homeContainer'>
                    <Grid container sx={{ width: "30%", margin: "4vh auto"}}>
                        <Typography variant='h1' className='pageHeading' gutterBottom>
                            SHIPPING & REFUNDS
                        </Typography>

                    </Grid>

                    <Grid container alignItems="baseline" sx={{ flexDirectioon: {xs: "column", sm: "row"}}}>
                      <Grid item mt={4} xs={12} sm={5} textAlign="left" margin="4vh auto">
                        <Typography variant='body1' className="mealName">
                              SHIPPING POLICY
                        </Typography>

                        <Divider sx={{ width: "20%", margin: "2vh auto", color: "#ccc" }} />

                        <Typography variant='body1' className="mealDescription" mt={4}>
                              This is a shippig policy section. <br/> I am a great place to update your customers about your shipping methods, packaging  and costs. 
                              <br />Use plain, straightforward languae to build trust and make sure that your customers stay loyal!
                        </Typography>

                        <Typography variant='body1' className="mealDescription" gutterBottom>
                              The second paragraph in your refund policy. Click here to add your own text and edit it, it's easy. Just click edit text.
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={5} textAlign="left" margin="4vh auto">
                        <Typography variant='body1' className="mealName">
                              REFUND POLICY
                        </Typography>

                        <Divider sx={{ width: "20%", margin: "2vh auto", color: "#ccc" }} />

                        <Typography variant='body1' className="mealDescription" mt={4} gutterBottom>
                              This is a refund policy section. <br/>I am a great place to let your customers know what to do in case they have changed their mind about their purchase, or if they are dissatisfied with a product.
                              <br/> Having a straightforward refund or exchange policy is a great way to build trust and reassure your customers that they can buy with confidence.
                        </Typography>

                        <Typography variant='body1' className="mealDescription" gutterBottom>
                              The second paragraph in your refund policy. Click here to add your own text and edit it, it's easy. Just click edit text.
                        </Typography>
                      </Grid>
                    </Grid>

                    <ConttactSection />
            </Grid>
         </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>


  );
}
