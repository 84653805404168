import React from 'react';
import { useCart } from '../../context/cartContext';
import { Button, Typography, Box, Container, Grid, Card, CardContent, CardActions, CardMedia } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import './cart.css';
import product1 from '../../assets/images/lemonadeImage.jpg';
import product2 from '../../assets/images/coffeeImage.jpg';
import product3 from '../../assets/images/greenSmoothie.jpg';
import product4 from '../../assets/images/mangoLassi.jpg';
import product5 from '../../assets/images/coconutWater.jpg';
import product6 from '../../assets/images/brownieImage.jpg';
import product7 from '../../assets/images/cheeseCake.jpg';
import product8 from '../../assets/images/applePie.jpg';
import product9 from '../../assets/images/mangoSorbet.jpg';
import product10 from '../../assets/images/chocolateMousse.jpg';
import product11 from '../../assets/images/tiramisu.jpg';
import product12 from '../../assets/images/fruitSalad.jpg';
import product13 from '../../assets/images/baklava.jpg';
import product14 from '../../assets/images/bruschetta.jpg';
import product15 from '../../assets/images/stuffedMushrooms.jpg';
import product16 from '../../assets/images/mangoLassi.jpg';
import product17 from '../../assets/images/garlicBread.jpg';
import product18 from '../../assets/images/nachosImages.jpg';
import product19 from '../../assets/images/falafelBites.jpg';
import product20 from '../../assets/images/hummusPlatter.jpg';
import product21 from '../../assets/images/veganBurger.jpg';
import product22 from '../../assets/images/chickenSandwich.jpg';
import product23 from '../../assets/images/margheritaPizza.jpg';
import product24 from '../../assets/images/dumplingStew.jpg';
import product25 from '../../assets/images/casesarSalad.jpg';
import product26 from '../../assets/images/chickenTacos.jpg';
import product27 from '../../assets/images/baconAndHamPizza.jpg';
import product28 from '../../assets/images/beefBurrito.jpg';
import product29 from '../../assets/images/carrotAndPotatoSoup.jpg';
import product30 from '../../assets/images/chickenNoodleSoup.jpg';
import { Link } from 'react-router-dom';

const  imageMap: { [key: string]: string } = {
 'images/lemonade.jpg': product1,
  'images/coffeeImage.jpg': product2,
  'greenSmoothie.jpg': product3,
  'images/mangoLassi.jpg': product4,
  'images/coconutWater.jpg': product5,
  'images/brownieImage.jpg': product6,
  'images/cheeseCake.jpg': product7,
  'images/applePie.jpg': product8,
  'images/mangoSorbet.jpg': product9,
  'images/chocolateMousse.jpg': product10,
  'images/tiramisu.jpg': product11,
  'images/fruitSalad.jpg': product12,
  'images/baklava.jpg': product13,
  'images/bruschetta.jpg': product14,
  'images/stuffedMushrooms.jpg': product15,
  'images/springRolls.jpg': product16,
  'images/garlicBread.jpg': product17,
  'images/nachosImages.jpg': product18,
  'images/falafelBites.jpg': product19,
  'images/hummusPlatter.jpg': product20,
  'images/veganBurger.jpg': product21,
  'images/chickenSandwich.jpg': product22,
  'images/margheritaPizza.jpg': product23,
  'images/dumplingStew.jpg': product24,
  'images/casesarSalad.jpg': product25,
  'images/chickenTacos.jpg': product26,
  'images/baconAndHamPizza.jpg': product27,
  'images/beefBurrito.jpg': product28,
  'images/carrotAndPotatoSoup.jpg': product29,
  'images/chickenNoodleSoup.jpg': product30,

};

const Cart: React.FC = () => {
    const { items, removeFromCart, clearCart, totalCost } = useCart();

    const getDeliveryStatus = () => {
        if(totalCost >= 1500) {
            return 'You qualify for free delivery.';
        } else {
            return 'The delivery fee is R70.'
        }
    };

    return (
        <Box sx={{ backgroundColor: "#D9D9D9", padding: "2vh"}}>
            {items.length === 0 ? (
                <div style={{ width: '100%', height: '50vh', textAlign: 'center', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: "center" }}>
                    <ProductionQuantityLimitsIcon sx={{ fontSize: "3rem" }}/>
                    <Typography variant="body1" sx={{ fontSize: "1.5rem"}}>Your cart is empty.</Typography>
                </div>
            ) : (
                <Container>
                    <Grid container spacing={2}>
                        {items.map(item => (
                            <Grid item xs={8} sm={10} key={item.id} sx={{ margin: "auto !important"}}>
                                <Typography variant="h1" className="subHeading" textAlign="left" sx={{ margin: "4vh !important"}}>
                                    WELCOME TO YOUR CART
                                </Typography>
            
                                <Typography variant="body1" className="mainText" textAlign="left" sx={{ margin: "4vh 1vw !important"}}>
                                    Here you'll find all the items included in your order. Placing an item in your shopping cart does not reserve that item or price.
                                    <br/> 
                                    We only reserve stock for your order once payment is received.
                                </Typography>
                                
                                <Card sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}, border: "1px solid #000", boxShadow: "none", justifyContent:  "space-between", alignItems: "center", margin: "auto"}}>
                                    <CardContent>
                                        {/* <img
                                            src={require(`../../assets/${item.image}`)}
                                            alt={item.name}
                                            style={{ width: 200, height: 250, borderRadius: '0.5vw' }}
                                        /> */}

                                        <CardMedia
                                            component="img"
                                            image={imageMap[item.image]}
                                            alt={item.name}
                                            sx={{ padding: 1, display: "flex", width: {xs: "60%", lg: "10%"} }}
                                         />

                                        <Typography gutterBottom variant="h6" className='subHeading' sx={{ fontSize: "32px !important", color: "#1F140B !important", textAlign: {xs: "center", md: "left"}}}>
                                            {item.name}
                                        </Typography>

                                        <Typography gutterBottom variant="body2" className='mainText' color="text.secondary" sx={{ fontSize: "20px !important"}}>
                                            {/* {meal.description} */}
                                        </Typography>

                                        <Grid item sx={{ display: "flex", flexDirection: "row !important", justifyContent: "left", alignItems: "center"}}>
                                            <Typography variant='body2' className='mainText'>Quantity: </Typography>

                                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" disabled>
                                                <InputLabel id={`select-label-${item.id}`}>{item.quantity}</InputLabel>

                                                <Select
                                                    labelId={`select-label-${item.id}`}
                                                    id={`select-${item.id}`}
                                                    defaultValue={item.quantity}
                                                    label={item.quantity}
                                                    sx={{
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#d18726', 
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#d18726', 
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#d18726', 
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value={1}>One</MenuItem>
                                                    <MenuItem value={2}>Two</MenuItem>
                                                    <MenuItem value={3}>Three</MenuItem>
                                                    <MenuItem value={40}>Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Typography variant='body2' className='price' textAlign="left">Price: R {item.price.toFixed(2)}</Typography>

                                    </CardContent>
                                    
                                    <CardActions>
                                        <Button variant='contained' className='removeButton' onClick={() => removeFromCart(item.id)} size="small">
                                            Remove
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button variant="contained" className='clearButton' onClick={clearCart}>
                            <DeleteIcon className='deleteIcon' />Clear Cart
                        </Button>
                    </Box>

                    <Grid container spacing={2} mt={2} justifyContent="center" flexDirection="column" alignItems="center">
                        <Grid item xs={6} sm={6}>
                            <Box sx={{ backgroundColor: "#fff1b5", color: "#fff", display: 'flex', justifyContent: "center", alignItems: "center" }} p={2}>
                                <Typography variant="body1" className='subTotal'>  
                                    TOTAL: 
                                </Typography>

                                <Typography variant="body1" className='subTotalPrice'> 
                                    R{totalCost.toFixed(2)} 
                                </Typography>
                            </Box>
                        </Grid>

                        {/* <Grid item xs={12} sm={4} display="flex" justifyContent="center" mt={2}>
                            <Button size="large" className="checkout" variant="contained">
                                <Link to="/checkout">
                                    PROCEED TO CHECKOUT
                                </Link>
                            </Button>
                        </Grid> */}
                    </Grid>
                </Container>
            )}
        </Box>
    );
};

export default Cart;
