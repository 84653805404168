import { useCart } from '../../context/cartContext';
import { Box, Button, ButtonBase, CardActionArea, Divider, Grid, Paper, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import homeImage from "../../assets/images/homeImage.svg";
// import categoryImage1 from "../../assets/images/categoryImage1.svg";
// import categoryImage2 from "../../assets/images/categoryImage2.svg";
import categoryImage3 from "../../assets/images/categoryImage3.svg";
import homeImage2 from "../../assets/images/homeImage2.png";
import teamImage1 from "../../assets/images/teamImage1.svg";
import teamImage2 from "../../assets/images/teamImage2.svg";
import "./home.css"
import NetflixCarousel from '../carousel/carousel';
import { MobileProductView } from '../mobileProductView/mobileProductView';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { HashLink as Link } from 'react-router-hash-link';
import mainPageImage from "../../assets/mainMealImage.jpg";
import spiceBowl from "../../assets/images/spiceBowl.jpg.jpg";
import { styled } from '@mui/material/styles';
import mealImage from "../../assets/images/mealPhoto.jpg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConttactSection from '../../components/looks/ContactSection';
import landingImage from "../../assets/images/graphic/3.png";
import categoryImage1 from "../../assets/images/Images/categoryImage1.jpg";
import categoryImage2 from "../../assets/images/Images/categoryImage2.jpg";
import mealTableImage from "../../assets/images/Images/mealTableImage.jpg";
import bottomImage1 from "../../assets/images/bottomPicture1.png";
import bottomImage2 from "../../assets/images/bottomPicture2.png";
import secondBackgroundImage from "../../assets/images/graphic/5.png";
import spoonImage from "../../assets/images/Images/spoonImage.jpg";

export function Home() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [notificationID, setNotificationID] = useState("")
    const { addToCart } = useCart();
    const isTabletOrAbove = useMediaQuery('(min-width:1024px)');

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    useEffect(() => {
        const getData = async () => {
    
          setNotificationID(Store.addNotification({
            title: "Wonderful!",
            message: "teodosii@react-notifications-component",
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
    
          Store.removeNotification(notificationID)
          await delay(1000);
          setIsLoaded(true)
    
        }
        getData()
    }, [])

  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
    backgroundImage: `url(${landingImage})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',
    flexDirection: 'column',
    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  
  <Grid item xs={12} sm={4}></Grid>

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));
  
    return (
        <>
        {isLoaded ? (
          <>
       <Grid container className='homeContainer'>
            <BackgroundContainer>
                <Typography className='Heading' variant="h1" sx={{ color: "#1F140B", fontSize: {xs: "2.5rem !important", md: '4rem !important'}}}>
                    Food that Feeds the Soul.
                </Typography>

                <Grid container>
                    <Link to="/menu" style={{ margin: "auto"}}>
                        <Button className="mainButton" sx={{ fontSize: {xs: "1.1rem !important", md: "1.8rem !important"}, margin: "2vh auto", width: {xs: "40vw !important", md: "13vw !important"}, 
                                    textTransform: "capitalize", borderRadius: "0.1vw", height: { xs: "10vh !important", md: "6vh"}, "&: hover": {
                                    backgroundColor: "#806044", fontWeight: 600, color: "white", 
                                 } 
                        }}>
                            View our Menu
                        </Button>
                    </Link>
                </Grid>
            </BackgroundContainer>

            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={8} my={4}>
                    <Typography variant='body1' className="subHeading" gutterBottom>
                        We aim to help you save time by cutting down on your cooking while still enjoying nutritious homemade meals
                    </Typography>
                </Grid>
            </Grid>
                
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={8} my={4}>
                    <Typography variant="body1" className="mainText" >
                        We offer a diverse selection through our menu and can be customized to fit various group sizes and portions
                    </Typography>
                </Grid>
            </Grid>

            <Grid container flexDirection="row" justifyContent="space-around" m={4}>
                <Grid item mt={4} flexDirection="column">
                    <Card sx={{ maxWidth: 300, boxShadow: "none", backgroundColor: "transparent" }}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={categoryImage1}
                            alt="Image 1 of our meals"
                            />
                            <Typography variant="body1" className="mainText" p={4}>
                                Dinner Parties
                            </Typography>
                    </Card>
                </Grid>

                <Grid item mt={4} flexDirection="column">
                    <Card sx={{ maxWidth: 300, boxShadow: "none", backgroundColor: "transparent" }}>
                            <CardMedia
                            component="img"
                            height="140"
                            image={categoryImage2}
                            alt="Inage 2 of our meals"
                            />
                            <Typography variant="body1" className="mainText" p={4}>
                                Main Meals
                            </Typography>
                    </Card>
                </Grid>
            </Grid>

            <Paper
                sx={(theme) => ({
                    p: 2,
                    margin: 'auto',
                    boxShadow: "none"
                })}
             >
                <Grid container spacing={2} my={4} flexDirection="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={5}>
                        <Typography variant="h2" className="Heading">
                            <span style={{ backgroundColor: '#ffda3d'}}>COMFORT</span> CRUMBS
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={5} direction="column" spacing={2} alignItems="center" justifyContent="center">
                        <Grid item alignItems="center" justifyContent="center">
                            <Typography gutterBottom variant="h2" className="subHeading">
                                <span className="subHeading" style={{ backgroundColor: '#ffda3d'}}>A word from our Founder</span>
                            </Typography>

                            <Typography variant="body2" className="mainText" my={4} sx={{ textAlign: "left" }}>
                                Food has always been my passion. I love how it brings people together and creates a sense of community. After years of dreaming abd planning, I finally topk the leap and opened my own catering/take-away business. It was a challenging journey but seeing the joy on my customer's faces as they enjoy their meals makes it all worth it. Come join us and experience the love and passion we put it in every bite.
                             </Typography>
                        </Grid>

                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <BackgroundContainer sx={{  backgroundImage: `url(${mealTableImage})` }}>

                </BackgroundContainer>
            </Grid>

            <Paper
                sx={(theme) => ({
                    p: 2,
                    margin: 'auto',
                    boxShadow: "none"
                })}
             >
                <Grid container spacing={2} my={4} flexDirection="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={5} direction="column" spacing={2} alignItems="center" justifyContent="center">
                        <Grid item alignItems="center" justifyContent="center">
                            <Typography gutterBottom variant="h2" className="subHeading">
                                <span className="subHeading" style={{ backgroundColor: '#ffda3d'}}>Giving back to the community</span>
                            </Typography>

                            <Typography variant="body2" className="mainText" my={4} sx={{ textAlign: "left" }}>
                                At Comfort Crumbs we believe in giving back to the community. That is why we have partnered with a local Cape Town Charity to help those in need. When you buy from us, you are not only receiving high-quality food, but you are also directly contributing to a good cause <b className="mainText">5% of every order goes directly to charity</b>

                                <br/>
                                <br/>

                                Thus you can feel good knowing that your purchase is helping to make a difference in someone's life. Order from us today and join us iin making a positive impact. 
                             </Typography>
                        </Grid>

                        <Grid item xs={10} sm={5} my={4} justifyContent="center" alignItems="center">
                            <Button className="secondButton" sx={{ flexDirection: "row !important", width: {xs: "50vw !important", md: "10vw !important"} }}>ORDER NOW <KeyboardArrowRightIcon sx={{ color: "#b8b8b8"}} />  </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={5} sx={{ display: "flex", flexDirection: "row", justifyContent: {xs: "center", md: "space-evenly"}}}>
                        <img src={spoonImage} />

                        
                    </Grid>
                </Grid>
            </Paper>

            <Grid container>
                <BackgroundContainer sx={{  backgroundImage: `url(${secondBackgroundImage})`, }}>
                    <Typography className='Heading' variant="h1" sx={{ color: "#D3B76B", fontSize: {xs: "2rem !important", md: '4rem !important'}}}>
                        CELEBRATE A SPECIAL OCCASION
                    </Typography>

                    <Typography mt={2} className='mainText' variant="h1" gutterBottom sx={{ color: "#FFE7A8"}}>
                        with our catered Dinner Party Meal
                    </Typography>

                    <Grid container>
                        <Link to="/products#ror" style={{ margin: "auto"}}>
                            <Button className="thirdButton" sx={{ margin: "2vh auto", width: {xs: "50vw !important", md: "13vw !important"}, 
                                        textTransform: "capitalize", borderRadius: "0.1vw", height: "6vh", "&: hover": {
                                        backgroundColor: "#ffda3d !important",  color: "white !important", 
                                    } 
                            }}>
                                View our Menu
                            </Button>
                        </Link>
                    </Grid>
                </BackgroundContainer>
            </Grid>

            <Grid container sx={{ backgroundColor: "#D3B76B"}}>
                <Grid item xs={10} p={4} margin="auto">
                    <Typography variant="body1" className="mainText" textAlign="center">
                        Indulge in our comfort food for your next dinner party/cozy night in. Our dishes are crafted with premium ingredients and cooked with precision to deliver a burst of flavours in every bite. From classic mac and cheese, to hearty stews, Our comfort food will satisfy your cravings and leave you feeling warm and cozy.
                        <br/>
                        Treat yourself and your guests to a delightful meal that will leave everyone happy and satisfied. 
                        <br/>
                        Order today and experience the ultimate in comfort and flavour!
                    </Typography>
                </Grid>
            </Grid>
            

            <ConttactSection />

       </Grid>
       </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>


  );
}
