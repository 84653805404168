import { useCart } from '../../context/cartContext';
import { Box, Button, ButtonBase, CardActionArea, Grid, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import homeImage from "../../assets/images/homeImage.svg";
import categoryImage1 from "../../assets/images/categoryImage1.svg";
import categoryImage2 from "../../assets/images/categoryImage2.svg";
import categoryImage3 from "../../assets/images/categoryImage3.svg";
import homeImage2 from "../../assets/images/homeImage2.png";
import teamImage1 from "../../assets/images/teamImage1.svg";
import teamImage2 from "../../assets/images/teamImage2.svg";
import NetflixCarousel from '../carousel/carousel';
import { MobileProductView } from '../mobileProductView/mobileProductView';
import { CircleLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import { HashLink as Link } from 'react-router-hash-link';
import mainPageImage from "../../assets/mainMealImage.jpg";
import spiceBowl from "../../assets/images/spiceBowl.jpg.jpg";
import { styled } from '@mui/material/styles';
import mealImage from "../../assets/images/mealPhoto.jpg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ConttactSection from '../../components/looks/ContactSection';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import * as React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';

export function StorePolicy() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [notificationID, setNotificationID] = useState("")
    const { addToCart } = useCart();
    const isTabletOrAbove = useMediaQuery('(min-width:1024px)');

    function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    useEffect(() => {
        const getData = async () => {
    
          setNotificationID(Store.addNotification({
            title: "Wonderful!",
            message: "teodosii@react-notifications-component",
            type: "warning",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
    
          Store.removeNotification(notificationID)
          await delay(1000);
          setIsLoaded(true)
    
        }
        getData()
    }, [])


  const BackgroundContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${spiceBowl})`, 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '100%',

    '@media only screen and (max-width: 600px)': {
      width: "100%",
    }
  });

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '90%',
    maxHeight: '90%',
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));
  

    return (
        <>
        {isLoaded ? (
          <>
            <Grid container className='homeContainer' flexDirection="column">
                    <Grid container sx={{ width: "30%", margin: "4vh auto"}}>
                        <Typography variant='h1' className='pageHeading' gutterBottom>
                            STORE POLICY
                        </Typography>
                    </Grid>

                    <Grid item mt={4} xs={12} sm={4} textAlign="left" margin="auto">
                       <Typography variant='body1' className="mealName">
                            CUSTOMER CARE
                       </Typography>

                       <Divider sx={{ width: "20%", margin: "2vh auto", color: "#ccc" }} />

                       <Typography variant='body1' className="mealDescription" mt={4}>
                            This is a customer care section. <br/> I am a great place to write a long text about your company and your services and most importantly, how to contact your store with queries. 
                            <br />
                            Writing a detailed Customer Care policy is a great way to build trust and reassure your customers that they can buy with confidence.
                       </Typography>

                       <Typography variant='body1' className="mealDescription" mb={4} gutterBottom>
                            The second paragraph in your refund policy. Click here to add your own text and edit it, it's easy. Just click edit text.
                       </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} textAlign="left" margin="4vh auto">
                       <Typography variant='body1' className="mealName">
                            PRIVACY & SAFETY
                       </Typography>

                       <Divider sx={{ width: "20%", margin: "2vh auto", color: "#ccc" }} />

                       <Typography variant='body1' className="mealDescription" gutterBottom mt={4}>
                            This is a privacy and safety section. <br/>I am a great place to let your customers know what to do in case they have changed their mind about their purchase, or if they are dissatisfied with a product.
                            <br/> Having a straightforward refund or exchange policy is a great way to build trust and reassure your customers that they can buy with confidence.
                       </Typography>

                       <Typography variant='body1' className="mealDescription" gutterBottom>
                            The second paragraph in your refund policy. Click here to add your own text and edit it, it's easy. Just click edit text.
                       </Typography>
                    </Grid>

                    <Grid item mb={4} xs={12} sm={4} textAlign="left" margin="4vh auto">
                       <Typography variant='body1' className="mealName">
                            WHOLESALE INQUIRIES
                       </Typography>

                      <Divider sx={{ width: "20%", margin: "2vh auto", color: "#ccc" }} />

                       <Typography variant='body1' className="mealDescription" gutterBottom mt={4}>
                            I am a wholesale section. I am a great place to inform other retailers about how they can sell your stunning products.
                            <br/>
                             Use plain language amd give us as much information as possible in order to promote your business and take it to the next level!
                       </Typography>

                       <Typography variant='body1' className="mealDescription" gutterBottom>
                            The second paragraph in your refund policy. Click here to add your own text and edit it, it's easy. Just click edit text.  The second paragraph in your refund policy. Click here to add your own text and edit it, it's easy. Just click edit text.
                       </Typography>
                    </Grid>

                    <Grid item  mt={4} mb={4} xs={12}>
                        <Typography variant='body1' className="mealName">
                            PAYMENT METHODS
                       </Typography>

                       <Divider sx={{ width: "10%", margin: "2vh auto", color: "#ccc" }} />

                        <List sx={{ width: '100%', maxWidth: 320, alignSelf: "center", margin: "auto" }}>
                          <ListItem alignItems="center" >
                                <FontAwesomeIcon icon={faCreditCard} size="2x" />
                                <ListItemText
                                sx={{ marginX: "1vw"}}
                                primary="Credit/Debit Cards"
                                />
                          </ListItem>

                          <Divider component="li" />

                          <br/>

                          <ListItem alignItems="center">
                                <ListItemAvatar>
                                <FontAwesomeIcon icon={faPaypal} size="2x" />
                                </ListItemAvatar>

                                <ListItemText
                                primary="PayPal"
                                />

                          </ListItem>
                          
                          <Divider component="li" />

                          <br/>

                          <ListItem alignItems="center">
                                <ListItemAvatar>
                                <FontAwesomeIcon icon={faHandHoldingDollar} size="2x" />
                                </ListItemAvatar>
                                <ListItemText
                                primary="Offline payments"
                                />
                          </ListItem>

                        </List>
                    </Grid>
                    
                    <ConttactSection />
            </Grid>
         </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}> <CircleLoader color="#d18726" size={150}/> </div>
        </div>
      )}
    </>


  );
}
