
import "./Footer.css";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Box, Typography, TextField } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: '2vh 0',
    textAlign: 'center',
    color: "#dadada",
    boxShadow: "none",
    margin: 0
})); 

export function Footer() {
    const [formState, setFormState] = useState({
        first_name: '',
        business_name: '',
        card_number: '',
        month_expiration: '',
        cvc: '',
        description: '',
        phone: '',
        email: '',
        crn: '',
        industry: '',
        website: '',
        currency: 'ZAR',
        street_name: '',
        city_name: '',
        province_name: '',
        zip_code: '',
        country_name: '',
        linked_users_invite: {}
      });
    
      // Regular expression for validating phone numbers (supports various formats)
      const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,2}))?([-\s./0-9]*\d[-\s./0-9]*)$/;
    
      // Regular expression for validating website URLs (supports HTTP and HTTPS)
      const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    
      // Email regref
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
      // Event handler for form input changes
      const [errors, setErrors] = useState({
        website: '',
        phone: '',
        email: '',
      });
    
      const handleChange = (event: any) => {
        const { name, value } = event.target;
        let errorMessage = '';
    
        // Perform validation based on the field name
        if (name === 'website') {
          console.log("Checking")
          if (!value.match(websiteRegex)) {
            errorMessage = 'Invalid website URL';
          }
        } else if (name === 'phone') {
          if (!value.match(phoneRegex)) {
            errorMessage = 'Invalid phone number';
          }
        } else if (name === 'email') {
          if (!value.match(emailRegex)) {
            errorMessage = 'Invalid email';
          }
        }
    
        // Update the form state and errors
        setFormState({ ...formState, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
      };

    return (
      <footer style={{ backgroundColor: "#fff", borderTop: "#FFDA3D 1px solid"}}>
        <Grid container spacing={2} my={4} flexDirection="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={5}>
            <Typography variant="h2" className="Heading">
              <span style={{ backgroundColor: '#ffda3d'}}>COMFORT</span> CRUMBS
            </Typography>
          </Grid>

          <Grid item xs={12} sm={5} direction="column" spacing={2} alignItems="center" justifyContent="center">
            <Grid item alignItems="center" justifyContent="center">
              <Typography gutterBottom variant="body1" className="mainText">
                Cape Town
              </Typography>

              <Typography gutterBottom variant="body1" className="mainText">
                Opening Hours: 10:30 - 20:00
              </Typography>

              <Typography gutterBottom variant="body1" className="mainText">
                TEL: +27 73 211 8538
              </Typography>

              <Typography gutterBottom variant="body1" className="mainText">
                info.comfortcrumbs@gmail.com
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" mt={4} sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}, justifyContent: "space-around"}}>
              <Link to="#" className="footerLinks">
                <Typography variant='body1' className="mainText footerLinks"> 
                <FontAwesomeIcon icon={faInstagram} style={{margin: "2vh 0.5vw"}} /> comfortcrumbs_ 
                </Typography>
              </Link>

              <Link to="#" className="footerLinks">
                <Typography variant='body1' className="mainText footerLinks"> 
                  <FontAwesomeIcon icon={faTiktok} style={{margin: "2vh 0.5vw"}} /> comfortcrumbs_ 
                </Typography>
              </Link>

              <Link to="#" className="footerLinks">
                <Typography variant='body1' className="mainText footerLinks"> 
                  <FontAwesomeIcon icon={faFacebook} style={{margin: "2vh 0.5vw"}} /> Comfort Crumbs
                </Typography>
              </Link>
          </Grid>
        </Grid>
      </footer>
    );
}
