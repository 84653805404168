
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Box, Typography, TextField, Card, CardMedia, CardContent, CardActions, Divider } from "@mui/material";
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import QuantityInput from "./NumberInput";
import meals from '../../data/meals';
import { CustomTextField } from '../../App';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: '2vh 0',
    textAlign: 'center',
    color: "#dadada",
    boxShadow: "none",
    margin: 0
})); 

interface CardProps {
    heading: string;
    description: string;
    image: string;
}


const ConttactSection: React.FC = () => {

    return (
        <Grid container p={4} sx={{ backgroundColor: "#ffffff", display: "flex", flexDirection: "column"}}>
                <Grid container flexDirection="column">
                    <Typography variant='h5' className="subHeading">CONTACT</Typography>
                    
                    <Divider sx={{ width: "20%", margin: "2vh auto", color: "#ccc" }} />

                    <Typography variant="body1" mt={4} className="subHeadingText" gutterBottom> TEL: +27 73 211 8538 / INFO: comfortcrumbs@gmail.com </Typography>

                    <Typography variant="body1" className="subHeadingText" gutterBottom> Cape Town </Typography>

                    <Typography variant="body1" className="subHeadingText" gutterBottom> OPENING HOURS: 8:00AM - 9:00PM </Typography>
                </Grid>
                
                <Grid container mt={4} spacing={2}>
                    <Grid item xs={12} sm={6} py={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                        <CustomTextField id="outlined-basic" label="Name" variant="outlined" />
                        <CustomTextField id="outlined-basic" label="Email" variant="outlined" sx={{ marginY: {xs: "2vh", md: "intial"}}} />
                        <CustomTextField id="outlined-basic" label="Subject" variant="outlined" />
                    </Grid>

                <Grid item xs={12} sm={6} py={4}>
                  <CustomTextField fullWidth id="fullWidth" label="Message" multiline rows={8} />
                </Grid>

                <Grid container sx={{ display: "flex", justifyContent: {xs: "center", md: "flex-end"} }}>
                  <Button variant="contained" className="secondButton" sx={{ width: {xs: "30vw !important", md: "10vw !important"}}}>Contact</Button>
                </Grid>
              </Grid>

            </Grid>
        
    );
}

export default ConttactSection;
